// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (
    window.matchMedia("(min-width:769px)").matches &&
    device === "pc"
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS
function topPageFunc() {
  var topSwiper = new Swiper("#sliderWrapFirst", {
    effect: "fade",
    speed: 3000,
    pagination: {
      el: ".swiper-pagination",
    },
    autoplay: {
      delay: 4000,
    },
    loop: true,
  });
}

function scFuncSp() {
  //元々の位置を取得
  var posBf = $(window).scrollTop();
  var netBtn = document.querySelector(".net-btn");

  $(window).on("scroll touchnmove", function() {
    //スクロールしたら位置を取得
    var posAf = $(this).scrollTop();

    //元々の位置とスクロール後の位置を比較
    if (posAf > -1 && posBf > -1 && posAf < posBf) {
      console.log("うえ！");
      netBtn.classList.remove("show");
    } else if (posAf > -1 && posBf > -1 && posAf > posBf) {
      console.log("した！");
      netBtn.classList.add("show");
    }

    if ($(window).scrollTop() === 0) {
      netBtn.classList.remove("show");
    }

    //元々の位置をスクロール後の位置に書き換える
    posBf = posAf;
  });
}

function subPageFunc() {
  if ($("#salonPage").length) {
  }
}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");
  topPageFunc();
  // SMOOTH SCROOL ATTRIBUTE NAME
  $('a[href^="#"]').click(function() {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });
  subPageFunc();
  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
    if ($("#topPage").length) {
      scFuncSp();
    }
  } else {
    console.log("Desktop");
  }
});
